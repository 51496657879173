import { AppRoutes } from './shared/constants/app-routes';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { redirectUnauthorizedTo, canActivate, hasCustomClaim, redirectLoggedInTo, AngularFireAuthGuard } from '@angular/fire/auth-guard';

const adminOnly = () => hasCustomClaim('admin');
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([AppRoutes.LOGIN]);
const redirectLoggedInToTransform = () => redirectLoggedInTo([AppRoutes.TRANSFORM]);
const belongsToAccount = (next) => hasCustomClaim(`account-${next.params.id}`);

const routes: Routes = [
  {
    path: '',
    redirectTo: AppRoutes.LOGIN,
    pathMatch: 'full'
  },
  {
    path: `${AppRoutes.LOGIN}`,
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: `${AppRoutes.TRANSFORM}`,
    ...canActivate(redirectUnauthorizedToLogin),
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
