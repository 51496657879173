import { EnvironmentModel } from '@mdix-transform/mdix-ui-lib';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: EnvironmentModel = {
  production: false,
  transformation: {
    baseUrl: 'http://ec2-54-90-253-38.compute-1.amazonaws.com:8080/mdmi/',
    // baseUrl: 'http://transform.mdixinc.net:8080/org.mdmi.rt.service/',
    mdmiTransformationUrl: 'transformation',
    sqlEndpoint: 'sql'
  },
  firebase: {
    apiKey: 'AIzaSyDq_AetV4rEHAhVrH0oMhowuRONOZNhy3k',
    authDomain: 'transform-c6142.firebaseapp.com',
    databaseURL: 'https://transform-c6142.firebaseio.com',
    projectId: 'transform-c6142',
    storageBucket: 'transform-c6142.appspot.com',
    messagingSenderId: '823839087804',
    appId: '1:823839087804:web:69c1eb31dff6ec6cdf44b9'
  }
  // mdmi_transformation_url: 'http://transform.mdixinc.net:8080/org.mdmi.rt.service/transformation',
  // mdmi_transformation_local_url: 'http://localhost:8080/test/transformation',
  // use_local_api: false,
  // firebase: {
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
