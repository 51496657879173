import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PublicLayoutModule, ImgHeaderModule, EnvironmentModule, ToastsModule } from '@mdix-transform/mdix-ui-lib';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    PublicLayoutModule,
    ImgHeaderModule.forRoot('./assets'),
    AngularFireModule.initializeApp(environment.firebase),
    FontAwesomeModule,
    ToastsModule,
    EnvironmentModule.forRoot(environment),
    BrowserAnimationsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
