import { AppRoutes } from './shared/constants/app-routes';
import { map, distinctUntilChanged, tap } from 'rxjs/operators';
import { UserService } from '@mdix-transform/mdix-ui-lib';
import { Component } from '@angular/core';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  faSignOutAlt = faSignOutAlt;

  constructor(private router: Router, public user: UserService) {
  }

  logout() {
    this.router.navigate([AppRoutes.LOGIN]);
    this.user.logout();
  }
}
