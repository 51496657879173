<mdix-ui-public-layout>
    <mdix-ui-img-header imgSrc="images/MDIX_logo_final_02_web.png">
        <mdix-ui-navbar *ngIf="user.isLoggedIn$ | async">
            <div [buttons]>
                <button class="btn btn-outline-light my-3 my-sm-0" type="button" (click)="logout()">
                    <div class="d-flex justify-content-center">
                        <span class="d-none d-md-block mr-2">
                            Logout
                        </span>
                        <fa-icon [icon]="faSignOutAlt"></fa-icon>
                    </div>
                </button>
            </div>
        </mdix-ui-navbar>
    </mdix-ui-img-header>
    <router-outlet></router-outlet>
</mdix-ui-public-layout>
<mdix-ui-toasts aria-live="polite" aria-atomic="true"></mdix-ui-toasts>